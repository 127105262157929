/* eslint-env node */
module.exports = {
    PORT: 3000,
    PROTOCOL: 'http',
    API_KEYS: {
        EGIS: 'IODxRhYcQwy9p0R9',
        GTM: 'GTM-KGQ8XWP',
        YANDEX_MAP: '39e14bfe-a781-4550-bc8d-fd89219833bb',
    },
    SC_DISABLE_SPEEDY: true,
};