import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const styledMobile = css`
    max-height: unset;
    position: relative;
`;

const SearchNotFoundStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: ${rem(306)};
    height: 100%;

    .image-found {
        margin: ${rem(48)} 0 ${rem(12)};
    }

    .description-found {
        text-align: center;
        font-size: ${rem(16)};
        line-height: ${rem(24)};
        margin-bottom: ${rem(52)};
        color: ${Variables.colors.greyNevadaColor};

        a {
            color: ${Variables.colors.dodgerBlue};

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    ${media(tablet, mobile)`${styledMobile}`}
    ${tablet`top: -15%`};
    ${mobile`top: -20%`};
`;

export { SearchNotFoundStyled };
