import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobile } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const StyledFooter = styled.div`
    background-color: ${Variables.colors.athensGray};
    color: ${Variables.colors.greyNevadaColor};
    margin-top: ${rem(80)};
    z-index: ${Variables.zIndexList.level1};
    font-size: ${rem(14)};

    .content {
        padding: ${rem(25)} 0;
    }

    ${mobile`
        margin-top: ${rem(48)};
        font-size: ${rem(17)};
    `};
`;

export default StyledFooter;
