import React, { FC } from 'react';
import { SearchNotFoundStyled } from './search-not-found.styles';
import { PicEmptyIcon } from '../../svg-components/geolocation/pic-empty';

interface SearchNotFoundProps {
    changeQuery: () => void;
}

export const SearchNotFound: FC<SearchNotFoundProps> = ({ changeQuery }) => {
    return (
        <SearchNotFoundStyled>
            <div className="image-found">
                <PicEmptyIcon />
            </div>
            <div className="description-found">
                Ничего не найдено.
                <br />
                Попробуйте <a onClick={changeQuery}>изменить запрос</a>
            </div>
        </SearchNotFoundStyled>
    );
};
