module.exports = {
    NEXT_PUBLIC_STAND: 'bridge',
    NODE_TLS_REJECT_UNAUTHORIZED: '0',
    BASE_URL: 'https://bridge-dev.online-chat.mts.ru',
    HEADER_ENV: 'stage',
    CHAT_ENV: 'bridge',
    API_URLS: {
        NODE_SUPPORT: 'http://online-chat-support-backend-bridge:8089/support-api/v1',
        SUPPORT: 'https://bridge-dev.online-chat.mts.ru/support-api/v1',
        CHAT: 'https://bridge-dev.online-chat.mts.ru',
    },
    WEBSOCKETS: {
        CHAT: 'wss://bridge-dev.online-chat.mts.ru/ws/chat',
    },
};