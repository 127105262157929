import { css } from 'styled-components';
import Variables from '../variables';

export const linkHover = css`
    &:hover {
        color: ${Variables.colors.darkBlueberryColor};
    }
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getVar = (name: string) => (props: any) => props[name];
