import styled, { css } from 'styled-components';
import { color } from '../../../styles/tokens/color';
import { zIndex } from '../../../styles/tokens/zIndex';

export const SearchContainer = styled.div<{ isLoading?: boolean }>`
    position: relative;
    display: flex;

    &:after {
        content: '';
        background-color: transparent;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.2s linear;
        ${({ isLoading }) =>
        isLoading &&
            css`
                background-color: ${color.loaderBackground};
            `};
        z-index: ${zIndex.level2};
    }
`;

export const CustomInputStyles = css`
    background-color: ${color.searchInputBg};
    border-color: transparent;
`;
