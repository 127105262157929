import React, {SVGProps} from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.69 4.69c-1.32 1.3-1.41 2.62-1.6 5.23a28.1 28.1 0 0 0 0 4.16c.19 2.61.28 3.92 1.6 5.23 1.3 1.32 2.62 1.41 5.23 1.6a28.09 28.09 0 0 0 4.16 0c2.61-.19 3.92-.28 5.23-1.6 1.32-1.3 1.41-2.62 1.6-5.23a28.1 28.1 0 0 0 0-4.16c-.19-2.61-.28-3.92-1.6-5.23-1.3-1.32-2.62-1.41-5.23-1.6a28.1 28.1 0 0 0-4.16 0c-2.61.19-3.92.28-5.23 1.6ZM13 7.99a1.25 1.25 0 1 1-2.5 0A1.25 1.25 0 0 1 13 8Zm-.3 3.3a1 1 0 0 1 .3.71v4a1 1 0 1 1-2 0v-3h-.5a1 1 0 1 1 0-2H12a1 1 0 0 1 .7.3Z"
                fill="#007CFF"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>
);
