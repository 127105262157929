import styled, { css } from 'styled-components';
import { ButtonViewEnum } from './button';
import em from '../../../helpers/em-converter';
import { rem } from '../../../helpers/rem-converter';
import {
    desktop,
    desktopHide,
    desktopXS,
    mediaHide,
    mobileHide,
    tabletHide,
} from '../../../styles/themes/media';
import { color } from '../../../styles/tokens/color';
import Variables from '../../../styles/variables';

interface StyledButtonProps {
    secondary?: boolean;
    loading?: boolean;
    view?: ButtonViewEnum;
}

const NarrowStyles = css`
    background-color: ${color.textLightPrimary} !important;
    height: ${rem(28)};
    padding: ${rem(4)} ${rem(8)};
    color: ${color.buttonSecondaryBg};
    font-family: ${Variables.fonts.fontRegular};

    ${desktopXS`
        height: ${rem(20)};
    `};
`;

export const BeforeIcon = styled.i<{ disabled?: boolean; loading?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: ${rem(4)};

    ${({ disabled }) => disabled && css`
        filter: grayscale(100%) invert(1) brightness(0.6);
    `};

    ${({ loading }) => loading && css`
        opacity: 0;
    `};
`;

export const Button = styled.button<StyledButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${Variables.colors.mainRed};
    color: ${Variables.colors.white};
    border: none;
    border-radius: ${rem(6)};
    font-family: ${Variables.fonts.fontMedium};
    font-size: ${em(14)};
    line-height: ${em(24)};
    padding-left: ${em(35)};
    padding-right: ${em(35)};
    cursor: pointer;
    outline: none;

    ${desktop`
        &:hover {
            background-color: ${Variables.colors.grayRed};
        }
    `};

    &.button-margin {
        margin-left: ${rem(24)};
    }

    &.responsive {
        ${mediaHide(mobileHide, tabletHide)}
    }

    ${p => p.view === ButtonViewEnum.narrow && NarrowStyles}

    &.transparent {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: ${Variables.colors.normalBlueberryColor};
        font-size: ${rem(14)};
        font-family: ${Variables.fonts.fontRegular};
        padding: 0;
        ${desktopHide};

        .text {
            padding: 0 0 ${rem(2)} ${rem(8)};
        }

        .arrow-left {
            transform: rotate(180deg);
        }


        ${desktop`
            &:hover {
              background-color: transparent;
            }
        `};
    }

    ${({ secondary }) => secondary && css`
        background-color: ${color.buttonSecondaryBg};
        color: ${Variables.colors.shark};

        ${desktop`
            &:hover {
                background-color: ${color.buttonSecondaryHoverBg};
                background-blend-mode: multiply;
            }
        `};
    `};

    ${({ disabled }) => disabled && css`
        background-color: ${color.buttonDisabledBg};
        color: ${color.buttonDisabled};
        cursor: default;
        pointer-events: none;

        ${desktop`
            &:hover {
                background-color: ${color.buttonDisabledBg};
                color: ${color.buttonDisabled};
            }
        `};
    `};

    ${({ loading }) => loading && css`
        pointer-events: none;
        color: transparent;

        &:hover {
            pointer-events: none;
            color: transparent;
        }
    `}
`;

export const LoaderWrapper = styled.i`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
