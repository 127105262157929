import styled from 'styled-components';
import Variables from '../../../styles/variables';

export const StyledCookiesHref = styled.a`
    color: ${Variables.colors.primaryLinkInverted};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${Variables.colors.normalBlueberryColor};
    }
`;
