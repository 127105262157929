import { WidgetChat, WidgetChatInterface } from './chat-simple';
import { Logo } from './logo';
import { SearchEntityTypesEnum } from './search-entity';
import { Section } from './section';
import { SegmentInfo } from './segment';

export interface ShortProduct {
    id: number;
    name: string;
    code: string;
    sefUrl: string;
    logo: Logo;
    description: string;
}

export class Product {
    constructor(
        public id: number = 0,
        public name: string = '',
        public code: string = '',
        public sefUrl: string = '',
        public parentSegment: SegmentInfo | null = null,
        public description: string | null = null,
        public url: string | null = null,
        public logo: Logo = new Logo(),
        public sections: Section[] = [],
        public widgetChat: WidgetChatInterface = new WidgetChat(),
        public totalSections: number = 0,
        public topSection: Section = new Section(),

        public type: SearchEntityTypesEnum.product = SearchEntityTypesEnum.product
    ) {
    }
}

export interface ShowChatWidgetInterface {
    showWidget: boolean;
}
