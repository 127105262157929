import React, { FC } from 'react';
import * as Styled from './geolocation-confirm.styles';
import Button from '../../form-controls/button/button';

interface PopupProps {
    onConfirm: () => void;
    cityName: string | null;
    onChangeRequest: () => void;
}

export const GeolocationConfirm: FC<PopupProps> = ({ onChangeRequest, cityName, onConfirm }) => {
    return (
        <Styled.GeolocationConfirmWrap>
            <Styled.GeolocationConfirmText>
                Ваше местоположение{' '}
                <span>
                    <strong>{cityName}?</strong>
                </span>
            </Styled.GeolocationConfirmText>
            <Styled.GeolocationConfirmButtons>
                <Button onClick={onChangeRequest}>Нет, изменить</Button>
                <Button onClick={onConfirm}>Да, спасибо</Button>
            </Styled.GeolocationConfirmButtons>
        </Styled.GeolocationConfirmWrap>
    );
};
