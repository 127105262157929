import styled from 'styled-components';
import { segmentsLineHeight } from '../../../constants/header';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';

export const Wrapper = styled.div<{
    withMenuOnMobile: boolean;
    isModalConfirmGeoOpened: boolean;
    isMyMtsApp: boolean;
}>`
    height: ${rem(32)};
    ${media(tablet, mobile)`
        height: ${({ withMenuOnMobile, isModalConfirmGeoOpened, isMyMtsApp }) => {
        let totalHeight = segmentsLineHeight;
        if (!isMyMtsApp && isModalConfirmGeoOpened) {
            totalHeight += 112;
        }
        if (!isMyMtsApp && withMenuOnMobile) {
            totalHeight += 32;
        }
        return rem(totalHeight);
    }};
    `};
`;
