import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { getVar } from '../../../styles/shared-css/helpers';
import { mobile, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const snackbarTabletStyles = css<{ isOpened: boolean }>`
    transform: translateY(calc(100% + ${rem(22)}));
    margin-bottom: ${rem(22)};
    ${({ isOpened }) =>
        isOpened &&
        css`
            transform: translateY(0);
        `};
`;

const snackbarMobileStyles = css<{ isOpened: boolean }>`
    ${snackbarTabletStyles};
    transform: translateY(calc(100% + ${rem(12)}));
    margin-bottom: ${rem(12)};
`;

export const StyledSnackbar = styled.div<{ animationDuration: number; isOpened?: boolean }>`
    position: absolute;
    display: flex;
    align-items: center;

    bottom: 0;
    margin-bottom: ${rem(24)};
    padding: ${rem(8)} ${rem(12)};
    background-color: ${Variables.colors.shark};
    border-radius: ${Variables.borderRadiusMd};
    color: ${Variables.colors.white};

    transform: translateY(calc(100% + ${rem(24)}));
    transition:
        transform ${getVar('animationDuration')}ms cubic-bezier(0.22, 0.61, 0.36, 1),
        opacity ${getVar('animationDuration')}ms cubic-bezier(0.22, 0.61, 0.36, 1);

    ${mobile`${snackbarMobileStyles as FlattenSimpleInterpolation}`};
    ${tablet`${snackbarTabletStyles as FlattenSimpleInterpolation}`};

    ${({ isOpened }) =>
        isOpened &&
        css`
            transform: translateY(0);
        `};
`;

export const StyledContent = styled.div`
    margin-right: ${rem(12)};
    font-family: ${Variables.fonts.fontRegular};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
`;

export const StyledIcon = styled.div`
    width: ${rem(28)};
    margin-right: ${rem(8)};
`;

export const StyledActionButton = styled.button`
    position: relative;
    border: none;
    outline: none;
    background: transparent;
    padding-left: ${rem(24)};
    padding-right: ${rem(12)};
    min-width: ${rem(48)};
    height: 100%;
    color: ${Variables.colors.white};
    cursor: pointer;
    font-family: ${Variables.fonts.fontMedium};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
    text-align: center;
    white-space: nowrap;

    &:hover {
        color: ${Variables.colors.greyHeatherColor};
    }

    &:before {
        content: '';
        height: 100%;
        width: ${rem(1)};
        background-color: ${Variables.colors.lightStroke};
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
`;
