import React from 'react';
import * as Styled from './geo-city.styles';
import { CurrentGeoIcon } from '../../svg-components/geolocation/current-geo';

export const GeoCity: React.FC<{
    cityName: string | null;
    onCityClick: () => void;
}> = ({ cityName, onCityClick }) => {

    return (
        <Styled.GeolocationCityWrap onClick={onCityClick}>
            <CurrentGeoIcon/>
            {cityName}
        </Styled.GeolocationCityWrap>
    );
};
