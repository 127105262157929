import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';

export const GeolocationCityWrap = styled.div`
    cursor: pointer;

    svg {
        margin-right: ${rem(4)};
    }
`;
