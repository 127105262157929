module.exports = {
    NEXT_PUBLIC_STAND: 'demo',
    NODE_TLS_REJECT_UNAUTHORIZED: '0',
    BASE_URL: 'https://demo.online-chat.mts.ru',
    HEADER_ENV: 'prod',
    CHAT_ENV: 'demo',
    API_URLS: {
        NODE_SUPPORT: 'http://online-chat-support-backend:8089/support-api/v1',
        SUPPORT: 'https://demo.online-chat.mts.ru/support-api/v1',
        CHAT: 'https://demo.online-chat.mts.ru',
    },
    WEBSOCKETS: {
        CHAT: 'wss://demo.online-chat.mts.ru/ws/chat',
    },
};