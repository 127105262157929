import styled, { css } from 'styled-components';
import { headerHeight } from '../../../constants/header';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';
import { color } from '../../../styles/tokens/color';
import Variables, { screenResolutions } from '../../../styles/variables';
import {
    contextWidth,
    sidePaddingsDesktop,
    sidePaddingsDesktopS,
    sidePaddingsDesktopXS,
    sidePaddingsMobile,
    sidePaddingsTablet,
} from '../../layout/container';

const geoStyles = css`
    padding-left: 0;
    margin-left: -4px;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${rem(5)};
    box-shadow: ${Variables.shadows.header};
    position: fixed;
    right: 0;
    left: 0;
    z-index: ${Variables.zIndexList.level5};
    background-color: ${color.backgroundLightPrimaryElevated};

    .prp-header {
        position: static !important;
        box-shadow: none !important;

        &__content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .prp-geo-line {
            .prp-geo-button {
                ${mobile`${geoStyles}`};
            }
        }

        .prp-menu__link {
            font-family: ${Variables.fonts.fontMedium};
        }
    }

    .prp-header-spacer {
        display: none;
    }
`;

const HeaderSpacer = styled.div`
    height: ${rem(headerHeight)};
    ${media(tablet, mobile)`
        height: ${rem(headerHeight)};
    `};
`;

const ModalWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

const adaptiveStyles = {
    all: {
        'max-width': rem(contextWidth),
    },
    [`(max-width: ${rem(contextWidth + sidePaddingsDesktop * 2)})`]: {
        'max-width': 'unset',
        'padding-left': `${rem(sidePaddingsDesktop)} !important`,
        'padding-right': `${rem(sidePaddingsDesktop)} !important`,
    },
    [`(max-width: ${rem(screenResolutions.desktopS.max)})`]: {
        'padding-left': `${rem(sidePaddingsDesktopS)} !important`,
        'padding-right': `${rem(sidePaddingsDesktopS)} !important`,
    },
    [`(max-width: ${rem(screenResolutions.desktopXS.max)})`]: {
        'padding-left': `${rem(sidePaddingsDesktopXS)} !important`,
        'padding-right': `${rem(sidePaddingsDesktopXS)} !important`,
    },
    [`(max-width: ${rem(screenResolutions.tablet.max)})`]: {
        'padding-left': `${rem(sidePaddingsTablet)} !important`,
        'padding-right': `${rem(sidePaddingsTablet)} !important`,
    },
    [`(max-width: ${rem(screenResolutions.mobile.max)})`]: {
        'padding-left': `${rem(sidePaddingsMobile)} !important`,
        'padding-right': `${rem(sidePaddingsMobile)} !important`,
    },
};

export { HeaderContainer, ModalWrapper, adaptiveStyles, HeaderSpacer };
