import { DistinctProductWithSelect } from './search-view.types';
import { SearchDistinctProduct, SearchDistinctSegment } from '../../../models/search-entity';
import { getProductByCode } from '../../../repositories/product.repository';

const querySeparator = ';';

export const getSegmentsCodes = async (productsQuery?: string) => {
    if (productsQuery) {
        const productCodes = productsQuery.split(querySeparator);
        const products = await Promise.all(productCodes.map(productCode => getProductByCode(productCode)));
        const allSegmentsCodes = products
            .filter(product => !!product.parentSegment)
            .map(product => product.parentSegment!.code);
        const segmentsCodesSet = new Set(allSegmentsCodes);

        return Array.from(segmentsCodesSet);
    }

    return [];
};

export const productsDistinctToQuery = (products: SearchDistinctProduct[]) => {
    const productsArray = products
        .filter((p) => p.selected)
        .map((p) => p.code);

    return [...new Set(productsArray)].join(querySeparator);
};

export const getProductsDistinctFromQuery = (productsQuery: string): SearchDistinctSegment[] => {
    if (!productsQuery) {
        return [];
    }
    const productsList = productsQuery.split(querySeparator);
    return [{
        products: productsList.reduce((acc, value) => {
            return [
                ...acc,
                {
                    selected: true,
                    code: value,
                },
            ];
        }, [] as DistinctProductWithSelect[]),
    }];
};

export const getProducts = (segments: SearchDistinctSegment[]) => {
    if (segments && segments.length > 0) {
        return segments.map(segment => segment.products).reduce((prev, curr) => prev?.concat(curr));
    }

    return [];
};

export const checkSelectedProduct = (product: SearchDistinctProduct, productsDistinct?: SearchDistinctSegment[]) => {
    if (productsDistinct && productsDistinct.length > 0) {
        const productValues = getProducts(productsDistinct);
        return !!productValues?.find((p) => p?.code === product.code && p.selected);
    }

    return false;
};
