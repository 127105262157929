import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const utmAppKeys = {
    utm_source: 'inhouse',
    utm_medium: 'mymts',
};

const checkUtm = (query: string = '') => {
    const source = `utm_source=${utmAppKeys.utm_source}`;
    const medium = `utm_medium=${utmAppKeys.utm_medium}`;

    return query.includes(medium) && query.includes(source);
};

export const useMyMtsApp = () => {
    const router = useRouter();
    const query = router.asPath.split('?')[1];
    const [isMyMtsApp, setIsMyMtsApp] = useState(false);

    useEffect(() => {
        setIsMyMtsApp(checkUtm(query));
    }, []);

    return { isMyMtsApp };
};
