import { css } from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import Variables from '../variables';

export const scrollbar = css`
    scrollbar-width: thin;
    scrollbar-color: ${Variables.colors.greyScrollColor} transparent;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: ${rem(5)};

        &-thumb {
            border: ${rem(4)} solid ${Variables.colors.greyScrollColor};
            border-radius: ${rem(8)};
        }
    }
`;
