import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useMyMtsApp } from '../../custom-hooks/use-my-mts-app';
import { desktopHide, mobileHide } from '../../styles/themes/media';

const RootLayoutWrap = styled.div<{ isMyMtsApp: boolean }>`
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: ${({ isMyMtsApp }) => isMyMtsApp ? 'flex-start' : 'space-between'};
    position: relative;
    overflow: hidden;

    .mobile-hidden {
        ${mobileHide};
    }

    .desktop-hidden {
        ${desktopHide};
    }
`;

const RootLayout: FC<{ children: ReactNode }> = ({ children }) => {
    const { isMyMtsApp } = useMyMtsApp();

    return (
        <RootLayoutWrap isMyMtsApp={isMyMtsApp}>
            {children}
        </RootLayoutWrap>
    );
};

export default RootLayout;
