import React, { useEffect, useState } from 'react';
import { SegmentsMenu } from './segments-menu';
import { menuItems } from './segments-menu.constants';
import * as Styled from './segments-menu.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useMyMtsApp } from '../../../custom-hooks/use-my-mts-app';
import Container from '../../layout/container';

export const SegmentsMenuMobile: React.FC = () => {
    const { isMyMtsApp } = useMyMtsApp();
    const router = useAppRouter();

    const [show, setShow] = useState(true);
    const routerPathName = router.pathname;
    const segmentsHref = menuItems.map(i => i.href);

    useEffect(() => {
        setShow(segmentsHref.includes(routerPathName));
    }, [routerPathName]);

    if (isMyMtsApp || !show) {
        return null;
    }

    return (
        <Styled.MobileMenuWrap>
            <Container>
                <SegmentsMenu isMobile />
            </Container>
        </Styled.MobileMenuWrap>
    );
};
