import { Url } from 'url';
import { useRouter } from 'next/router';
import { useQuery } from './use-query';
import { withUtm } from '../helpers/with-utm';

// скопирован из nextjs из router.d.ts
interface TransitionOptions {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
}

export const useAppRouter = () => {
    const query = useQuery();
    const router = useRouter();

    return {
        ...router,
        push: (url: string | Partial<Url>, as?: string, options?: TransitionOptions) => {
            const newUrl = withUtm(url, query);
            const newAs = withUtm(as, query);

            return router.push(newUrl, newAs, options);
        },
        replace: (url: string | Partial<Url>, as?: string, options?: TransitionOptions) => {
            const newUrl = withUtm(url, query);
            const newAs = withUtm(as, query);

            return router.replace(newUrl, newAs, options);
        },
    };
};
