import React from 'react';
import * as Styled from './header-top-controls.styles';
import { useMyMtsApp } from '../../../custom-hooks/use-my-mts-app';
import { GeoCity } from '../geo-city/geo-city';
import { SegmentsMenu } from '../segments-menu/segments-menu';

export const HeaderTopControls: React.FC<{
    cityName: string | null;
    onCityClick: () => void;
}> = ({ cityName, onCityClick }) => {
    const { isMyMtsApp } = useMyMtsApp();

    return (
        <Styled.HeaderTopControls>
            {!isMyMtsApp && <SegmentsMenu />}
            <GeoCity cityName={cityName} onCityClick={onCityClick} />
        </Styled.HeaderTopControls>
    );
};
