import { getProducts } from '../components/containers/search-view/search-view.helpers';
import { DistinctProductWithSelect } from '../components/containers/search-view/search-view.types';
import { isProd } from '../helpers/env.helper';
import { transliterate } from '../helpers/gtm.helper';
import { isServer } from '../helpers/isServer';
import { logThis } from '../helpers/log-this';
import { checkTouchPoint, getNumberType, undefinedToNull } from '../helpers/web-analytics.helper';
import { Article } from '../models/article';
import { CurrentUserModel } from '../models/current-user.model';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
    WebAnalyticsNumberTypeEnum,
} from '../models/enums/web-analytics.enum';
import { Product } from '../models/product';
import { SearchDistinctSegment } from '../models/search-entity';
import { Nullable } from '../models/types/common.type';
import { logLevels } from '../models/types/logger.type';
import { WebAnalyticsDataInterface } from '../models/web-analytics.model';
import store from '../redux/store';
import { getProfile } from '../repositories/current-user.repository';

type pushEventParams = Nullable<Partial<WebAnalyticsDataInterface>>;

const mockDataLayer = {
    push(gtmData) {
        // eslint-disable-next-line no-console
        console.log('GTM event pushed', gtmData);
    },
} as Nullable<WebAnalyticsDataInterface>[];

class GtmService {
    static isDataLayerMocked = false;
    static user: CurrentUserModel | null = null;
    protected static userRequested = false;

    protected static get dataLayer(): Nullable<WebAnalyticsDataInterface>[] {
        if (isServer) {
            return [];
        }

        if (!isProd()) {
            GtmService.isDataLayerMocked = true;
            return mockDataLayer;
        }

        return isServer ? [] : window.dataLayer;
    }

    protected static get currentProduct(): Product {
        return store.getState().article?.currentProduct;
    }

    protected static get currentArticle(): Article {
        return store.getState().article?.currentArticle;
    }

    protected static get productName(): ReturnType<typeof transliterate> {
        return transliterate(this.currentProduct?.name, true);
    }

    protected static get articleName(): ReturnType<typeof transliterate> {
        return transliterate(this.currentArticle?.name, true);
    }

    static pushPage(eventData: { screenName: string; productName?: string | null }) {
        this.push({
            event: WebAnalyticsEventEnum.scrn,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
            ...eventData,
        });
    }

    static pushEvent(eventData: pushEventParams = {}) {
        this.push({
            event: WebAnalyticsEventEnum.vntSupport,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            ...eventData,
        });
    }

    static async push(data = {}) {
        const user = await this.fetchUser();
        const userAuth = user ? 1 : 0;

        try {
            const screenName = isServer ? '' : window.location.pathname;

            const gtmObject: WebAnalyticsDataInterface = {
                // defaults
                userId: user?.id,
                userAuth,
                currentTariff: transliterate(user?.currentTariff) as string,
                numberType: getNumberType(user?.numberType) as WebAnalyticsNumberTypeEnum,
                accountType: transliterate(user?.accountType, true) as string,
                screenName,
                touchPoint: checkTouchPoint(),
                event: WebAnalyticsEventEnum.vntSupport,
                actionGroup: WebAnalyticsActionGroupEnum.interactions,
                abonent: undefined,
                eventContent: undefined,
                eventContext: undefined,
                productName: undefined,
                ...data,
            };

            if (GtmService.isDataLayerMocked) {
                this.dataLayer.push(undefinedToNull(gtmObject));
            } else {
                const timeForGtmLoad = 50;

                const dataLayerWaiter = () =>
                    setTimeout(() => {
                        if (!this.dataLayer) {
                            dataLayerWaiter();
                        } else {
                            this.dataLayer.push(undefinedToNull(gtmObject));
                        }
                    }, timeForGtmLoad);

                dataLayerWaiter();
            }
        } catch (e) {
            logThis('gtm.push', {
                level: logLevels.error,
                label: 'Ошибка gtm dataLayer.push',
                data: {
                    error: {
                        useId: user?.id,
                        ...e,
                    },
                    ...data,
                },
            });
        }
    }

    protected static async fetchUser() {
        if (!this.userRequested) {
            const profile = await getProfile();
            this.user = profile ? {
                id: profile['profile:guid'],
                name: profile['profile:name:org'],
                abonent: null,
                numberType: profile['profile:type'],
                accountType: profile['profile:type'],
                currentTariff: profile['mobile:tariff'],
                phone: profile['mobile:phone'],
                isAuth: 1,
            } : null;
            this.userRequested = true;
        }

        return this.user;
    }

    protected static log(name: string): void {
        if (!isProd()) {
            // eslint-disable-next-line no-console
            console.log('name', name);
        }
    }
}

export class GtmEvents extends GtmService {
    static pageEvent(screenName: string, productName?: string) {
        this.pushPage({
            screenName,
            productName: transliterate(productName, true),
        });
        this.log(this.pageEvent.name);
    }

    static chatEvent(eventLabel: string, article?: Article, customEventData: pushEventParams = {}) {
        let eventContent = WebAnalyticsEventContentEnum.pageProduct;
        let eventContext = null;

        if (article) {
            eventContent = WebAnalyticsEventContentEnum.article;
            eventContext = transliterate(article.name, true) ?? null;
        }

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntChat,
            eventCategory: WebAnalyticsEventCategoryEnum.chat,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            productName: this.productName,
            eventLabel: transliterate(eventLabel, true),
            eventContent: transliterate(eventContent, true),
            eventContext,
            ...customEventData,
        });
        this.log(this.chatEvent.name);
    }

    static chatEventActionShowElement(eventLabel: string, eventContent?: boolean) {
        this.chatEvent(eventLabel, undefined, {
            eventAction: WebAnalyticsEventActionEnum.elementShow,
            eventContent: eventContent ? WebAnalyticsEventContentEnum.firstMessageInChat : null,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
        });
    }

    static goToMainHeaderEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntMain,
            eventCategory: WebAnalyticsEventCategoryEnum.menu,
            eventAction: WebAnalyticsEventActionEnum.menuClick,
            eventLabel: WebAnalyticsEventLabelsEnum.support,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        });
        this.log(this.goToMainHeaderEvent.name);
    }

    static breadcrumbsEvent(_text: string, level: number) {
        const text = transliterate(_text, true);
        let eventLabel = text;

        // первый уровень breadcrumbs
        if (level === 1) {
            eventLabel = WebAnalyticsEventLabelsEnum.back;
        }

        this.pushEvent({
            eventContent: WebAnalyticsEventContentEnum.breadcrumbs,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel,
            eventCategory: text,
            productName: text,
            eventContext: `${level}lvl`,
        });
        this.log(this.breadcrumbsEvent.name);
    }

    static logoClickEvent(name: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntCross,
            eventCategory: transliterate(name, true),
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: `Na_sait_${transliterate(name, true)}`,
            productName: transliterate(name, true),
        });
        this.log(this.logoClickEvent.name);
    }

    static productNameClick(_name: string, _segmentTitle?: string) {
        const name = transliterate(_name, true);
        const segmentTitle = transliterate(_segmentTitle, true);

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntMain,
            eventCategory: WebAnalyticsEventCategoryEnum.home,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: name,
            screenName: '/',
            eventContent: segmentTitle,
            productName: name,
        });
        this.log(this.productNameClick.name);
    }

    static likeEvent(usefulness: boolean) {
        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: usefulness ? WebAnalyticsEventLabelsEnum.yes : WebAnalyticsEventLabelsEnum.no,
            eventContent: this.articleName,
            productName: this.productName,
        });
        this.log(this.likeEvent.name);
    }

    static articleInnerHrefClick(href: string, text: string) {
        const mainHrefPart = href.replace(/(^\w+:|^)\/\//, '').split('?')[0];

        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: transliterate(text, true),
            eventContent: this.articleName,
            eventContext: transliterate(mainHrefPart, true),
            productName: this.productName,
        });
        this.log(this.articleInnerHrefClick.name);
    }

    static articleInnerAccordionClick(text: string) {
        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.elementOpen,
            eventLabel: WebAnalyticsEventLabelsEnum.questionWatch,
            eventContent: this.articleName,
            eventContext: transliterate(text, true),
        });
        this.log(this.articleInnerAccordionClick.name);
    }

    static questionCategoryClick(sectionName: string, sectionId: number | null) {
        this.pushEvent({
            eventCategory: this.productName || '',
            eventAction: WebAnalyticsEventActionEnum.cardOpen,
            eventLabel: transliterate(sectionName, true),
            eventContext: sectionId,
            productName: this.productName,
        });
        this.log(this.questionCategoryClick.name);
    }

    static questionClick(name: string, linkText: string, linkPosition: number) {
        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: transliterate(linkText, true),
            eventContent: transliterate(name, true),
            eventContext: linkPosition,
            productName: this.productName,
        });
        this.log(this.questionClick.name);
    }

    static feedbackOptionClick(optionText?: string) {
        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.no,
            eventContent: this.articleName,
            eventContext: transliterate(optionText, true),
            productName: this.productName,
        });
        this.log(this.feedbackOptionClick.name);
    }

    static feedbackWithComment() {
        this.pushEvent({
            eventCategory: this.productName,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.articleImprovement,
            eventContent: this.articleName,
            eventContext: 'drugoe',
            actionGroup: WebAnalyticsActionGroupEnum.conversions,
            productName: this.productName,
        });
        this.log(this.feedbackWithComment.name);
    }

    static startSearchRequestHome(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.startSearchRequestHome.name);
    }

    static searchFieldFocus(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.searchFieldFocus.name);
    }

    static searchError(productsDistinct: SearchDistinctSegment[]) {
        const selected = getProducts(productsDistinct).find((p) => p.selected);
        const selectedName = transliterate(selected?.name, true) ?? WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.rejected,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent: selectedName,
            eventContext: WebAnalyticsEventLabelsEnum.searchNoResults,
        });
        this.log(this.searchError.name);
    }

    static searchAddProduct(product?: DistinctProductWithSelect) {
        let eventAction = WebAnalyticsEventActionEnum.filterAdd;
        let eventLabel: string | undefined = WebAnalyticsEventLabelsEnum.allServices;

        if (product) {
            const selected = product.selected;
            eventAction = selected ? WebAnalyticsEventActionEnum.filterAdd : WebAnalyticsEventActionEnum.filterRemove;
            eventLabel = transliterate(product.name, true) as string | undefined;
        }

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction,
            eventLabel,
        });
        this.log(this.searchAddProduct.name);
    }

    static searchProductArticleClick(questionName: string, productName?: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.cardOpen,
            eventLabel: transliterate(questionName, true),
            productName: transliterate(productName, true),
        });
        this.log(this.searchProductArticleClick.name);
    }

    static authorizationSuccess(profileType: string, guid: string, tariff: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntLogin,
            eventCategory: WebAnalyticsEventCategoryEnum.authorization,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.authorizationSuccess,
            accountType: transliterate(profileType, true),
            actionGroup: WebAnalyticsActionGroupEnum.conversions,
            userAuth: 1,
            userId: guid,
            currentTariff: transliterate(tariff),
            numberType: getNumberType(profileType),
        });
        this.log(this.authorizationSuccess.name);
    }

    static searchMoreProductsClick(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        const productName = productSectionName ? transliterate(productSectionName, true) : null;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.more,
            eventValue: null,
            eventContent,
            eventContext: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName,
        });
        this.log(this.searchMoreProductsClick.name);
    }

    static videoStart(videSrc: string, duration?: number) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntVideo,
            eventCategory: WebAnalyticsEventCategoryEnum.video,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.videoStart,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            eventContent: videSrc,
            eventContext: duration,
            filterName: this.articleName,
            productName: this.productName,
        });
        this.log(this.videoStart.name);
    }

    static videoPause(videSrc: string, percent?: number) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntVideo,
            eventCategory: WebAnalyticsEventCategoryEnum.video,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.videoPause,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            eventContent: videSrc,
            eventContext: percent,
            filterName: this.articleName,
            productName: this.productName,
        });
        this.log(this.videoStart.name);
    }

    static articleReadEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntSupport,
            eventCategory: WebAnalyticsEventCategoryEnum.article,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.view15,
            eventValue: null,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
            productName: this.productName,
        });

        this.log(this.articleReadEvent.name);
    }
}
