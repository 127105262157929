import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { defaultHeaderHeight, segmentsLineHeight } from '../../constants/header';
import { rem } from '../../helpers/rem-converter';

// чтобы после скролла по якорю, контент не был строго прибит к краю экрана
const scrollIndentFromHeader = 20;
const scrollPadding = defaultHeaderHeight + scrollIndentFromHeader + segmentsLineHeight;

const GlobalStyle = createGlobalStyle`
    ${reset}

    html {
        scroll-padding-top: ${scrollPadding}px;
    }

    html, body {
        font-family: MTSCompact-Regular, sans-serif;
        --ecosystem-header-font-family: MTSCompact;
        background-color: white;
    }

    :root {
        --ecosystem-header-font-family: 'MTSCompact-Regular';
    }

    * {
        overflow-wrap: break-word;
    }

    img,
    video,
    code,
    pre {
        max-width: 100%;
    }

    html,
    button,
    input,
    select,
    textarea {
        font: inherit;
    }

    figure.table {
        overflow-x: auto;
    }

    table, th, td {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #bbc1c7;
    }

    strong {
        font-family: MTSText-Medium, sans-serif;
    }

    ol {
        list-style: decimal;
    }

    .hidden {
        display: none !important;
    }

    .arrow-icon {
        padding-right: ${rem(8)};
        padding-left: ${rem(8)};
        align-self: center;
    }
`;

export { GlobalStyle };
