import { useEffect } from 'react';
import { ProcessEnv } from '../helpers/env.helper';
import { updEnv } from '../helpers/update-env';

export const useInitialProps = (env: ProcessEnv) => {
  useEffect(() => {
    if(env) {
      updEnv(env);
    }
  }, []);
}
