import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobile } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const GeolocationHeaderStyles = styled.div`
    width: 100%;
    max-height: ${rem(44)};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${Variables.shadows.geolocationLine};
    margin-bottom: ${rem(38)};
    position: relative;

    .title-line {
        font-size: ${rem(17)};
        line-height: ${rem(24)};
        color: ${Variables.colors.shark};
        font-family: ${Variables.fonts.fontMedium};
    }

    .close {
        position: absolute;
        right: 3.5%;
        cursor: pointer;
    }

    ${mobile`margin-bottom: ${rem(26)}`};
`;

export { GeolocationHeaderStyles };
