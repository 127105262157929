import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { scrollbar } from '../../../styles/shared-css/custom-scroll';
import { media, mobile, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const styledMobile = css`
    top: 114%;
    max-width: unset;
    width: 100%;
`;

const StyledGeolocationFoundList = styled.div`
    position: absolute;
    top: 25%;
    max-width: ${rem(608)};
    width: 100%;
    max-height: ${rem(292)};
    z-index: ${Variables.zIndexList.top};
    background-color: ${Variables.colors.white};
    box-shadow: ${Variables.shadows.geolocation};
    border-radius: ${Variables.borderRadiusMd};
    overflow-y: auto;

    ${scrollbar};
    ${media(mobile, tablet)`${styledMobile}`};

    .item {
        max-height: ${rem(62)};
        padding: ${rem(10)} ${rem(12)} ${rem(6)};
        font-size: ${rem(17)};
        line-height: ${rem(24)};
        display: flex;
        flex-direction: column;

        span {
            font-size: ${rem(14)};
            line-height: ${rem(20)};
            color: ${Variables.colors.greyNevadaColor};
        }

        &:hover {
            cursor: pointer;
            background-color: ${Variables.background.geolocation};
        }
    }
`;

export { StyledGeolocationFoundList };
