
import { FC, useEffect, useState } from 'react';
import * as Styled from './header-top-controls-spacer.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useMyMtsApp } from '../../../custom-hooks/use-my-mts-app';
import { menuItems } from '../segments-menu/segments-menu.constants';

export const HeaderTopControlsSpacer: FC<{ isModalConfirmGeoOpened: boolean }> = ({ isModalConfirmGeoOpened }) => {
    const { isMyMtsApp } = useMyMtsApp();
    const [withMenuOnMobile, setWithMenuOnMobile] = useState(true);
    const router = useAppRouter();
    const routerPathName = router.pathname;
    const segmentsHref = menuItems.map(i => i.href);

    useEffect(() => {
        setWithMenuOnMobile(segmentsHref.includes(routerPathName));
    }, [routerPathName]);

    return <Styled.Wrapper
        withMenuOnMobile={withMenuOnMobile}
        isModalConfirmGeoOpened={isModalConfirmGeoOpened}
        isMyMtsApp={isMyMtsApp}
    />;
};
