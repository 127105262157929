export enum WebAnalyticsEventCategoryEnum {
    menu = 'menu',
    crossElements = 'kross_elementy',
    authorization = 'avtorizaciya',
    home = 'glavnaya',
    registration = 'registraciya',
    documentation = 'documentaciya',
    communicator = 'kommunikator',
    mobileId = 'mobile_id',
    clickPayment = '1click_payment',
    cashback = 'cashback',
    ePay = 'epay',
    mtsAgents = 'mts_agenty',
    clients = 'klienty',
    clientConnection = 'podkluchenie_klienta',
    blog = 'blog',
    agentsSchool = 'shkola_agentov',
    main = 'main',
    error = 'error',
    search = 'poisk',
    chat = 'chat',
    video = 'video',
    article = 'article'
}

export enum WebAnalyticsEventActionEnum {
    menuClick = 'menu_click',
    buttonClick = 'button_click',
    confirmed = 'confirmed',
    cardClick = 'card_click',
    formConfirmed = 'form_confirmed',
    formRejected = 'form_rejected',
    linkClick = 'link_click',
    socialClick = 'social_click',
    elementClick = 'element_click',
    appClick = 'app_click',
    cardOpen = 'card_open',
    error = 'error',
    submit = 'submit',
    elementOpen = 'element_open',
    filterAdd = 'filter_add',
    filterRemove = 'filter_remove',
    filterSet = 'filter_set',
    rejected = 'rejected',
    hideForm = 'hide_form',
    elementShow = 'element_show',
}

export enum WebAnalyticsPageTypeEnum {
    main = 'main',
    infoPage = 'infopage',
    searchPage = 'searchpage',
}

export enum WebAnalyticsActionGroupEnum {
    nonInteractions = 'non_interactions',
    interactions = 'interactions',
    conversions = 'conversions',
}

export enum WebAnalyticsEventEnum {
    pageView = 'mtsPageview',
    cross = 'mtsCross',
    login = 'mtsLogin',
    event = 'mtsEvent',
    vntCross = 'vntCross',
    vntLogin = 'vntLogin',
    vntEvent = 'vntEvent',
    vntMain = 'vntMain',
    vntSupport = 'vntSupport',
    vntPoisk = 'vntPoisk',
    vntChat = 'vntChat',
    vntVideo = 'vntVideo',
    scrn = 'scrn',
    error = 'error',
}

export enum WebAnalyticsTouchPointEnum {
    mobile = 'mobile',
    web = 'web',
    webview = 'webview',
}

export enum WebAnalyticsEventContentEnum {
    upBanner = 'up_banner',
    forClients = 'dlya_klientov',
    forStaff = 'dlya_sotrudnikov',
    forDevices = 'dlya_ustroistv',
    downPart = 'down_part',
    home = 'glavnaya',
    breadcrumbs = 'breadcrumbs',
    pageProduct = 'stranica_produkta',
    article = 'statya',
    //chat
    firstMessageInChat = 'pervoe_soobschenie_v_chate',
    numberPhone = 'nomer_telefona',
    link = 'ssylka',
    email = 'email',
    exceededSize = 'prevyshen_ves',
    nps = 'nps',
    fcr = 'fcr',
    operator = 'operator',
    client = 'polzovatel',
    file = 'fail',
}

export enum WebAnalyticsEventLabelsEnum {
    yes = 'da',
    no = 'net',
    articleImprovement = 'uluchshenie_stati',
    consultationRequest = 'zayavka_na_konsultaciu',
    connectionRequest = 'zayavka_na_podkluchenie',
    feedbackForm = 'forma_obratnoi_svyazi',
    signUp = 'zaregistriruisya',
    sendRequest = 'ostavit_zayavku',
    joinServiceRequest = 'zayavka_na_sovmestnyi_servis',
    moreDetails = 'podrobnee',
    toPlug = 'podkluchit',
    toContact = 'svyazatsya',
    newClient = 'novyi_klient',
    toMainPage = 'na_glavnuu',
    request = 'zayavka',
    questionsLeft = 'ostalis_voprosy',
    error = 'error',
    //new
    signIn = 'voity',
    logo = 'logo',
    search = 'poisk',
    authorizationSuccess = 'uspeshnaya_avtorizaciya',
    searchError = 'oshibka_poiska',
    searchNoResults = 'net_rezultatov',
    dislike = 'dislike',
    like = 'like',
    success = 'success',
    back = 'nazad',
    allServices = 'vse_servisy',
    questionWatch = 'posmotret_vopros',
    widgetChat = 'vidzhet_chata',
    telegram = 'telegram',
    whatsapp = 'whatsapp',
    hideChat = 'skryt_chat',
    more = 'esche',
    searchUsefulness = 'poleznost_poiska',
    numberPhone = 'nomer_telefona',
    phone = 'telefon',
    support = 'spravka',
    videoStart = 'nachalo_prosmotra',
    videoPause = 'video_ostanovleno',
    view15 = 'view_15'
}

export enum WebAnalyticsNumberTypeEnum {
    virtual = 'virt',
    real = 'real',
    other = 'other',
}

export enum WebAnalyticsAccountTypeEnum {
    mobile = 'mobile',
    stv = 'stv',
    fix = 'fix',
    mgts = 'mgts',
    error = 'error',
}
