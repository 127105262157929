import React, {SVGProps} from 'react';

export const PicEmptyIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={160}
        height={160}
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                d="M80 160c44.183 0 80-35.817 80-80 0-44.182-35.817-80-80-80C35.818 0 0 35.818 0 80c0 44.183 35.818 80 80 80Z"
                fill="#F2F3F7"
            />
            <path
                d="M125.09 121.091c0 4.017-20.188 7.273-45.09 7.273-24.903 0-45.092-3.256-45.092-7.273 0-4.017 20.189-7.273 45.091-7.273 24.903 0 45.091 3.256 45.091 7.273Z"
                fill="#E2E5EB"
            />
            <path
                d="M112 120.364H49.455A1.455 1.455 0 0 1 48 118.909V37.455c0-.803.652-1.455 1.455-1.455h42.43a2.91 2.91 0 0 1 2.058.852l18.659 18.66c.546.545.853 1.285.853 2.057v61.34c0 .803-.652 1.455-1.455 1.455Z"
                fill="#fff"
            />
            <path
                d="M49.454 36.89c-.104 0-.2.027-.282.074l-.89-1.538c.345-.2.746-.315 1.172-.315h4.715v1.778h-4.715Zm18.858 0h-9.429V35.11h9.43v1.778Zm14.144 0h-9.43V35.11h9.43v1.778Zm9.43 0H87.17V35.11h4.714a3.8 3.8 0 0 1 .983.13l-.46 1.717a2.023 2.023 0 0 0-.523-.069Zm1.428.59a2.015 2.015 0 0 0-.418-.32l.888-1.54c.285.164.55.367.787.603l3.11 3.11-1.257 1.257-3.11-3.11Zm-44.35-.307a.558.558 0 0 0-.076.282v5.43h-1.777v-5.43c0-.426.114-.827.314-1.173l1.538.89Zm56.789 12.748-6.22-6.22 1.258-1.258 6.219 6.22-1.257 1.258Zm-56.865-1.606v10.861h-1.777v-10.86h1.777Zm63.085 7.826-3.11-3.11 1.257-1.257 3.11 3.11a3.8 3.8 0 0 1 .604.786l-1.54.89a2.001 2.001 0 0 0-.321-.42l.629-.628-.629.629Zm.592 1.428a2.03 2.03 0 0 0-.069-.523l1.717-.46c.086.318.13.648.13.983v5.112h-1.778v-5.112Zm-63.677 7.037v10.86h-1.777v-10.86h1.777Zm63.677 13.41V67.792h1.778v10.224h-1.778Zm-63.677 2.881v10.86h-1.777v-10.86h1.777Zm63.677 12.454V83.127h1.778v10.224h-1.778Zm-63.677 3.837v10.861h-1.777v-10.86h1.777Zm63.677 11.498V98.462h1.778v10.224h-1.778Zm-63.677 4.793v5.43c0 .105.028.2.075.282l-1.538.891a2.337 2.337 0 0 1-.314-1.173v-5.43h1.777Zm63.677 5.43v-5.111h1.778v5.111c0 .426-.115.827-.315 1.173l-1.538-.891a.552.552 0 0 0 .075-.282Zm-63.393.491a.565.565 0 0 0 .282.075h5.212v1.778h-5.212c-.426 0-.827-.115-1.173-.315l.891-1.538Zm64 1.538c-.346.2-.747.315-1.173.315h-5.212v-1.778h5.212c.105 0 .2-.028.282-.075l.891 1.538Zm-53.294-1.463h10.424v1.778H59.878v-1.778Zm15.636 0H85.94v1.778H75.515v-1.778Zm15.637 0h10.424v1.778H91.151v-1.778ZM94.545 95.942c0 3.669-6.187 3.322-13.818 3.322-7.632 0-13.819.347-13.819-3.322 0-3.67 6.187-8.306 13.819-8.306 7.631 0 13.818 4.637 13.818 8.306ZM71.273 76A3.636 3.636 0 1 1 64 76a3.636 3.636 0 0 1 7.273 0ZM97.454 76a3.636 3.636 0 1 1-7.273 0 3.636 3.636 0 0 1 7.273 0Z"
                fill="#6E7782"
            />
        </g>
    </svg>
);