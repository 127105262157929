import { capitalize } from './capitalize';

const maxLetters = 100;

export const transliterate = (text?: string | null, toLowerCase?: boolean) => {
    if (text === null || text === undefined) {
        return text;
    }

    const replacer = (match: string) => {
        const isUpper = match === match.toUpperCase();
        const enLetter = dictionary.get(match.toLowerCase()) ?? match;
        return isUpper ? capitalize(enLetter) : enLetter;
    };

    const replaced = text.replace(/[а-яА-ЯёЁ-]|\s/g, replacer).substring(0, maxLetters);
    return toLowerCase ? replaced.toLowerCase() : replaced;
};

const dictionary = new Map([
    ['а', 'a'],
    ['б', 'b'],
    ['в', 'v'],
    ['г', 'g'],
    ['д', 'd'],
    ['е', 'e'],
    ['ё', 'e'],
    ['ж', 'zh'],
    ['з', 'z'],
    ['и', 'i'],
    ['й', 'i'],
    ['к', 'k'],
    ['л', 'l'],
    ['м', 'm'],
    ['н', 'n'],
    ['о', 'o'],
    ['п', 'p'],
    ['р', 'r'],
    ['с', 's'],
    ['т', 't'],
    ['у', 'u'],
    ['ф', 'f'],
    ['х', 'h'],
    ['ц', 'c'],
    ['ч', 'ch'],
    ['ш', 'sh'],
    ['щ', 'sch'],
    ['ъ', ''],
    ['ы', 'y'],
    ['ь', ''],
    ['э', 'e'],
    ['ю', 'u'],
    ['я', 'ya'],
    [' ', '_'],
    ['-', '_'],
]);
