import React, {
    FC,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
    StyledActionButton,
    StyledContent,
    StyledIcon,
    StyledSnackbar,
} from './snackbar.styles';
import { closeSnackbarAction } from '../../../redux/snackbar/snackbar-actions';
import { SnackbarTypes } from '../../../redux/snackbar/types';
import { InfoIcon } from '../../svg-components/search-rating/info';

interface SnackbarProps {
    id: number;
    action?: () => void;
    actionText?: string;
    duration?: number;
    type: SnackbarTypes;
}

const animationDuration = 600;

const isInfo = (type: SnackbarTypes) => type === 'info';

export const Snackbar: FC<PropsWithChildren<SnackbarProps>> = ({
    children,
    id,
    type,
    action = () => {},
    actionText,
    duration,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpened(true);

        // Если задано время отображения, то скрываем по истечении этого времени
        if (duration) {
            setTimeout(() => hideBar(), duration);
        }
    }, []);

    const onClickActionButtonHandler = () => {
        hideBar();
        action();
    };

    const hideBar = () => {
        setIsOpened(false);
        setTimeout(() => dispatch(closeSnackbarAction(id)), animationDuration);
    };

    if (isInfo(type)) {
        return (
            <StyledSnackbar isOpened={isOpened} animationDuration={animationDuration}>
                <StyledIcon>
                    <InfoIcon />
                </StyledIcon>
                <StyledContent>{children}</StyledContent>
                <StyledActionButton onClick={onClickActionButtonHandler}>{actionText}</StyledActionButton>
            </StyledSnackbar>
        );
    }

    return null;
};
